const initialState = {
  featureCardsLoadInProgress: false,
  featureCardsHaveLoaded: false
};

export const FEATURE_CARD_FETCH_REQUESTED = 'FeatureCardActions/FEATURE_CARD_FETCH_REQUESTED';
export const FEATURE_CARD_FETCH_SUCCESS = 'FeatureCardActions/FEATURE_CARD_FETCH_SUCCESS';
export const FEATURE_CARD_FETCH_FAILED = 'FeatureCardActions/FEATURE_CARD_FETCH_FAILED';

export default (state = initialState, action) => {
  switch (action.type) {
    case FEATURE_CARD_FETCH_REQUESTED:
      return {
        ...state,
        featureCardsLoadInProgress: true,
        featureCardsHaveLoaded: false
      };
    case FEATURE_CARD_FETCH_SUCCESS:
      return {
        ...state,
        featureCardsLoadInProgress: false,
        featureCardsHaveLoaded: true
      };
    case FEATURE_CARD_FETCH_FAILED:
      return {
        ...state,
        featureCardsLoadInProgress: false
      };
    default:
      return state;
  }
};
