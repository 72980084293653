import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import lqip from 'lqip.macro';
import { Container, Dimmer, Grid, Loader, Sidebar } from 'semantic-ui-react';
import ContentLoader from 'react-content-loader';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import 'scss/global.scss';
import SiteHeader from '_regions/SiteHeader';
import SidebarNav from '_regions/SidebarNav/SidebarNav';
import { getCurrentScreenSize, imgSrcSizeMap, setCurrentScreenSize } from '_system/breakpoints';
import IntersectionObserver from '_system/IntersectionObserver';
import LoadedModule from '_system/LoadedModule';
import BackgroundImageWrapper from '_regions/backgroundImageWrapper';
import { setSemaphore } from './_system/semaphore';

import 'react-toastify/dist/ReactToastify.min.css';

const Home = lazy(() => import('_pages/Home'));
const About = lazy(() => import('_pages/About'));
const Dynamic = lazy(() => import('_pages/Dynamic'));
const Shop = lazy(() => import('_pages/Shop'));
const Cart = lazy(() => import('_pages/Shop/Cart'));
const CheckoutForm = lazy(() => import('_pages/Shop/CheckoutForm'));
const Contact = lazy(() => import('_pages/Contact'));
const ProductPage = lazy(() => import('_pages/Shop/Products/ProductPage'));
const Maintenance = lazy(() => import('_pages/Maintenance'));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarActive: false,
      currentScreenSize: '',
      year: new Date().getFullYear()
    };

    this.updateScreenSize = this.updateScreenSize.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  componentDidMount() {

    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  }

  updateScreenSize() {
    this.props.setCurrentScreenSize(getCurrentScreenSize());
  }

  openSidebar() {
    this.setState({
      sidebarActive: true
    });
  }

  closeSidebar() {
    this.setState({
      sidebarActive: false
    });
  }

  renderWrapper() {
    const placeholder = (
      <div className="loading-placeholder">
        <Dimmer active inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </div>
    );

    return (
      <div className={this.props.bodyClass}>
        <Grid className="page-content-wrapper" padded="vertically">
          <Grid.Row className="header-wrapper">
            <Grid.Column>
              <SiteHeader sidebarClickHandler={this.openSidebar} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="content-main">
            <Grid.Column>
              <Suspense fallback={placeholder}>
                <Switch>
                  <Route key="home" exact path="/" component={Home} />
                  <Route key="about" path="/about" component={About} />
                  <Route key="shop" path="/shop" component={Shop} />
                  <Route path="/cart" component={Cart} />
                  <Route exact path="/checkout" component={CheckoutForm} />
                  <Route path="/checkout/:phase" component={CheckoutForm} />
                  <Route key="contact" path="/contact" component={Contact} />
                  <Route path="/product/*" component={ProductPage} />
                  <Route path="/utils/maintenance" component={Maintenance} />
                  <Route component={Dynamic} />
                </Switch>
              </Suspense>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render() {
    const placeholder =
      this.props.bodyClass === 'front' ? lqip('images/front-bg/front-bg-large.png') : '';
    const imgUrl = this.props.bodyClass === 'front' ? this.props.bodyBanner : '';
    const bgoptions = {
      placeholder: {
        style: {
          opacity: 0.8,
          filter: 'blur(5px)'
        }
      },
      styles: {
        default: {
          backgroundImage: placeholder,
          opacity: 0.8,
          filter: 'blur(5px)'
        },
        loaded: {}
      },
      bannerCoverage: this.props.bodyBannerMode
    };
    const frontBgSrcSet = {};

    // Gotta be a better way to do this...
    Object.keys(imgSrcSizeMap).forEach(screenSize => {
      const imgSize = imgSrcSizeMap[screenSize];

      const imgPath = `${process.env.REACT_APP_BESPOKE_ASSET_URL}/themes/custom/bespoke/images/front-bg`;
      const filename = `/front-bg-${imgSize}`;

      frontBgSrcSet[screenSize] = {
        webp: `${imgPath + filename}.webp`,
        src: `${imgPath + filename}.png`
      };
    });

    return (
      <div>
        <Helmet>
          <title>Bespoke Blossoms</title>
          <meta name="geo.placename" content="Victoria, BC" />
          <meta name="description" content="Bespoke Blossoms is a flower company in Victoria, BC that uses local product to create arrangements for weddings, corporate, and fundraisers." />
          <meta name="abstract" content="Bespoke Blossoms is a flower company in Victoria, BC that uses local, sustainable products to create exquisite arrangements for your home, your business, or your event." />
          <meta name="keywords" content="Flowers, Victoria, Wedding, fundraiser" />
          <meta name="abstract" content="Bespoke Blossoms is a flower company in Victoria, BC that uses local, sustainable products to create exquisite arrangements for your home, your business, or your event." />
          <meta name="keywords" content="Flowers, Victoria, Wedding, fundraiser" />
          <meta property="og:locality" content="Victoria" />
          <meta property="og:region" content="British Columbia" />
          <meta property="og:postal_code" content="V8W4A3" />
          <meta property="og:country_name" content="Canada" />
          <meta property="og:email" content="info@bespokeblossoms.com" />
        </Helmet>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <Sidebar.Pushable as="div" className="page-content-wrapper">
          <SidebarNav visible={this.state.sidebarActive} hideHandler={this.closeSidebar} />

          <Sidebar.Pusher as="div" dimmed={this.state.sidebarActive}>
            {this.props.bodyClass === 'front' ? (
              <BackgroundImageWrapper
                className={[this.props.bodyClass, 'bgblur'].join(' ')}
                src={imgUrl}
                srcSet={frontBgSrcSet}
                breakpoint={this.state.currentScreenSize}
                placeholder={placeholder}
                fluid="true"
                options={bgoptions}
              >
                {this.renderWrapper()}
              </BackgroundImageWrapper>
            ) : (
              this.renderWrapper()
            )}

            <IntersectionObserver>
              <LoadedModule
                placeholder={<Container><ContentLoader /></Container>}
                component={() => import("_regions/Footer")}
                twitterAccount="https://twitter.com/bespokeblossoms"
                facebookAccount="https://www.facebook.com/BespokeBlossom"
                instagramAccount="https://instagram.com/bespokeblossoms"
                copyrightYear={this.state.year}
                phoneNumber="250-382-0243"
                emailAddress="info@bespokeblossoms.com"
                versionTag={`${process.env.REACT_APP_VERSION_TAG}`}
              />
            </IntersectionObserver>

          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapState = (state) => {

  return {
    semaphore: state.semaphoreReducer.semaphore
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentScreenSize,
      setSemaphore
    },
    dispatch
  );

export default withRouter(connect(mapState, mapDispatchToProps)(App));
