import { Model, attr } from 'redux-orm';

class BlogModel extends Model {
  static reducer(action, SessionModel) {
    switch (action.type) {
      case 'CREATE_BLOG_CARD':
      case 'UPDATE_BLOG_CARD':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_BLOG_CARD':
        SessionModel.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static parse(rawData) {

    const defaultImg = {
      alt: "Flower Graphic",
      title: "flowers",
      teaserImage: {
        url: `${process.env.REACT_APP_BESPOKE_ASSET_URL}sites/default/files/styles/latest_content_teaser/public/default_images/flower-extra2.png`,
        height: 200,
        width: 300
      }
    };

    const parsedData = {
      id: rawData.entityUuid,
      type: rawData.entityBundle,
      title: rawData.title,
      url: rawData.path.alias,
      bgImage: rawData.fieldImage === null ? defaultImg.teaserImage.url : rawData.fieldImage.teaserImage.url,
      bgImageWidth: rawData.fieldImage === null ? defaultImg.teaserImage.width : rawData.fieldImage.teaserImage.width,
      bgImageHeight: rawData.fieldImage === null ? defaultImg.teaserImage.height : rawData.fieldImage.teaserImage.height,
      alt: rawData.fieldImage === null ? defaultImg.alt : rawData.fieldImage.alt
    };

    return parsedData;
  }
}

BlogModel.modelName = 'BlogModel';

BlogModel.fields = {
  id: attr(),
  type: attr(),
  title: attr(),
  url: attr(),
  smallFormatBgImage: attr(),
  smallFormatBgImageWidth: attr(),
  smallFormatBgImageHeight: attr(),
  alt: attr()
};

BlogModel.options = {
  idAttribute: 'id'
};

export default BlogModel;
