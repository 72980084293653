import { Model, attr, fk } from 'redux-orm';

class ImageDerivative extends Model {
  static reducer(action, SessionModel) {
    switch(action.type) {
      case 'CREATE_IMAGE_DERIVATIVE':
      case 'UPDATE_IMAGE_DERIVATIVE':
        SessionModel.upsert(action.payload);

        break;
      case 'DELETE_IMAGE_DERIVATIVE':
        SessionModel.withId(action.payload.id).delete();

        break;
      case 'PURGE_IMAGE_DERIVATIVES':
        SessionModel.all().delete();
        break;
      default:
        break;
    }
  }
}

ImageDerivative.modelName = 'ImageDerivative';

ImageDerivative.fields = {
  id: attr(),
  image: fk({to: 'Image', as: 'image', relatedName: 'derivatives'}),
  breakpoint: attr(),
  url: attr(),
  height: attr(),
  width: attr()
};

ImageDerivative.options = {
  idAttribute: 'id'
};

export default ImageDerivative;