import React from 'react';
import { Grid, Container, Icon } from 'semantic-ui-react';

import CartIcon from '_pages/Shop/Cart/CartIcon';
import logo from 'logo.png';
import MainNav from './MainNav';

import 'scss/header.scss';

const SiteHeader = props => {
  return (
    <Container as="header">
      <Grid verticalAlign="middle" padded="vertically">
        <Grid.Row>
          <Grid.Column width={6} only="computer">
            <MainNav
              className="menu-left"
              items={[
                { name: 'home', label: 'Home', route: '/', exact: true },
                { shop: 'shop', label: 'Shop', route: '/shop' },
                { name: 'weddings', label: 'Weddings', route: '/weddings' }
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={7} tablet={6} computer={4} textAlign="center" className="logo-spot">
            <a href="/" title="Bespoke Blossoms Home">
              <img src={logo} alt="Bespoke Blossoms Logo" />
            </a>
          </Grid.Column>
          <Grid.Column width={5} only="computer">
            <MainNav
              onItemClick={item => props.handleItemClick(item)}
              items={[
                { name: 'about', label: 'About Us', route: '/about' },
                { name: 'contact', label: 'Contact', route: '/contact' }
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={6} computer={1}>
            <CartIcon />
          </Grid.Column>
          <Grid.Column className="hamburger" only="mobile tablet" width={3}>
            <Icon name="bars" size="big" color="grey" onClick={props.sidebarClickHandler} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default SiteHeader;
