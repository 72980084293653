import { Model, attr } from 'redux-orm';

class TestimonyModel extends Model {
  static reducer(action, SessionModel) {
    switch (action.type) {
      case 'CREATE_TESTIMONY_CARD':
      case 'UPDATE_TESTIMONY_CARD':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_TESTIMONY_CARD':
        SessionModel.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static parse(rawData) {
    const parsedData = {
      id: rawData.entityUuid,
      type: rawData.entityBundle,
      body: rawData.body ? rawData.body.value : null,
      bgImage: rawData.fieldImage.teaserImage.url,
      bgImageWidth: rawData.fieldImage.teaserImage.width,
      bgImageHeight: rawData.fieldImage.teaserImage.height,
      alt: rawData.fieldImage.alt
    };

    return parsedData;
  }
}

TestimonyModel.modelName = 'TestimonyModel';

TestimonyModel.fields = {
  id: attr(),
  type: attr(),
  body: attr(),
  bgImage: attr(),
  bgImageWidth: attr(),
  bgImageHeight: attr(),
  alt: attr()
};

TestimonyModel.options = {
  idAttribute: 'id'
};

export default TestimonyModel;
