import { canUseWebP } from 'react-img-webp';

export const SCREEN_SIZE = 'BreakpointActions/SCREEN_SIZE';

export const breakpoints = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  large: 1200,
  widescreen: 1920
};

export const imgSrcSizeMap = {
  mobile: 'small',
  tablet: 'medium',
  computer: 'large',
  widescreen: 'x-large'
};

export function getCurrentScreenSize() {
  const screenWidth = window.innerWidth;

  return Object.keys(breakpoints)
    .reverse()
    .filter(breakpointName => screenWidth >= breakpoints[breakpointName])[0];
}

const initialState = {
  screenSize: getCurrentScreenSize()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_SIZE:
      return {
        ...state,
        screenSize: action.screenSize
      };
    default:
      return state;
  }
};

export const setCurrentScreenSize = screenSize => {
  return dispatch => {
    dispatch({
      type: SCREEN_SIZE,
      screenSize
    });
  };
};

export const pickResponsiveImage = (imageList) => {

  const breakpoint = getCurrentScreenSize();

  if (imageList.mobile === undefined) {
    throw new Error("No 'mobile' key defined. Default not present.");
  }

  let selectedImage = '';

  if (imageList[breakpoint] === undefined) {
    selectedImage = imageList.mobile;
  } else {
    selectedImage = imageList[breakpoint];
  }

  if (canUseWebP()) {

    const parsedUrl = new URL(selectedImage);

    return `${selectedImage.replace(/\.[^.$]+$/, '.webp')}${parsedUrl.search}`;
  }

  return selectedImage;
};
