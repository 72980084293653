import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import FontActions from '_system/fontActions';
import BreakpointActions from '_system/breakpoints';
import semaphoreReducer from '_system/semaphore';
import { createReducer } from 'redux-orm';
import orm from '_system/orm';

import featureCardsReducers from '_pages/Home/_content/FeatureNews/reducers';
import blogCardsReducers from '_pages/Home/_content/Blog/reducers';
import productIndexReducers from '_pages/Shop/Products/_content/ProductIndex/reducers';
import dynamicReducers from '_pages/Dynamic/reducers';
import productPageReducers from '_pages/Shop/Products/ProductPage/_reducers';
import checkoutFormRecipientReducers from '_pages/Shop/CheckoutForm/CheckoutFormRecipient/_reducers';
import checkoutFormSender from '_pages/Shop/CheckoutForm/CheckoutFormSender/_reducers';
import checkoutFormOptions from '_pages/Shop/CheckoutForm/CheckoutFormOptions/_reducers';
import checkoutReviewActions from '_pages/Shop/CheckoutForm/CheckoutFormReview/_reducers';
import shippingDatetime from '_pages/Shop/CheckoutForm/CheckoutFormShipping/_reducers';
import shippingMethods from '_pages/Shop/CheckoutForm/CheckoutFormShipping/ShippingMethodSelection/_reducers';
import weddingGallery from '_pages/Dynamic/_content/WeddingGalleryBlock/_reducers';
import drupalImageReducers from '_regions/DrupalImage/_reducers';


// TODO: Implement dynamic reducers. This is pretty crazy.
export default history =>
  combineReducers({
    router: connectRouter(history),
    FontActions,
    BreakpointActions,
    featureCards: featureCardsReducers,
    blogCards: blogCardsReducers,
    productIndexSettings: productIndexReducers,
    orm: createReducer(orm),
    dynamicReducers,
    productPageReducers,
    checkoutFormRecipientReducers,
    dateTime: shippingDatetime,
    shippingMethods,
    checkoutFormSender,
    checkoutFormOptions,
    checkoutReviewActions,
    weddingGallery,
    drupalImageReducers,
    semaphoreReducer
    // insert reducers here.
  });
