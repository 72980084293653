import { attr, Model } from 'redux-orm';

class Tag extends Model {
  static reducer(action, SessionModel) {
    switch(action.type) {
      case 'CREATE_TAG':
      case 'UPDATE_TAG':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_TAG':
        SessionModel.withId(action.product.id).delete();
        break;
      case 'PURGE_TAGS':
        SessionModel.all().delete();
        break;
      default:
        break;
    }
  }

  static parse(rawData) {

    return {
      id: rawData.entityUuid,
      label: rawData.entityLabel
    }
  }
}

Tag.modelName = 'Tag';

Tag.fields = {
  id: attr(),
  label: attr()
};

Tag.options = {
  idAttribute: 'id'
};

export default Tag;