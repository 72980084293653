import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import rootReducer from '_system/rootReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { routerMiddleware } from 'connected-react-router';

import history from '_system/history';

export default function configureStore(preloadedState = {}) {
  const enhancers = [];
  const persistenceEnabled = process.env.REACT_APP_REDUX_PERSISTENCE_ENABLED === 'true' || false;
  const middlewares = [routerMiddleware(history), thunk];

  // Add in redux devtools in dev environment as an enhancer.
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  // Create persistant state to carry across page reloads.
  const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    stateReconciler: autoMergeLevel2,
    keyPrefix: 'bb_'
  };

  const persistedReducer = persistenceEnabled
    ? persistReducer(persistConfig, rootReducer(history))
    : rootReducer(history);

  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(...middlewares), ...enhancers)
  );

  return store;
}

export const store = configureStore();

export const persistor = persistStore(store);