const initialState = {
  productPageLoadInProgress: false,
  productPageHasLoaded: false,
  productPage: null,
  activeVariation: null
};

export const PRODUCT_PAGE_FETCH_REQUESTED = 'ProductPageActions/PRODUCT_PAGE_FETCH_REQUESTED';
export const PRODUCT_PAGE_FETCH_SUCCESS = 'ProductPageActions/PRODUCT_PAGE_FETCH_SUCCESS';
export const PRODUCT_PAGE_FETCH_FAILED = 'ProductPageActions/PRODUCT_PAGE_FETCH_FAILED';
export const SAVE_PRODUCT_PAGE = 'ProductPageActions/SAVE_PRODUCT_PAGE';
export const SET_ACTIVE_VARIATION = 'ProductPageActions/SET_ACTIVE_VARIATION';

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_PAGE_FETCH_REQUESTED:
      return {
        ...state,
        productPageLoadInProgress: true,
        productPageHaveLoaded: false
      };
    case PRODUCT_PAGE_FETCH_SUCCESS:
      return {
        ...state,
        productPageLoadInProgress: false,
        productPageHasLoaded: true
      };
    case PRODUCT_PAGE_FETCH_FAILED:
      return {
        ...state,
        productPageLoadInProgress: false
      };
    case SAVE_PRODUCT_PAGE:
      return {
        ...state,
        productPage: action.payload
      };
    case SET_ACTIVE_VARIATION:
      return {
        ...state,
        activeVariation: action.payload
      };
    default:
      return state;
  }
};
