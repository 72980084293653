export const SET_SEMAPHORE = 'Semaphore/SET_SEMAPHORE';

const initialState = {
  semaphore: false
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_SEMAPHORE:
      return {
        ...state,
        semaphore: Date()
      };
    default:
      return state;
  }
}

export function setSemaphore() {
  return dispatch => {
    dispatch({
      type: SET_SEMAPHORE
    })
  }
}