const initialState = {
  blogCardsLoadInProgress: false,
  blogCardsHaveLoaded: false
};

export const BLOG_CARD_FETCH_REQUESTED = 'BlogCardActions/BLOG_CARD_FETCH_REQUESTED';
export const BLOG_CARD_FETCH_SUCCESS = 'BlogCardActions/BLOG_CARD_FETCH_SUCCESS';
export const BLOG_CARD_FETCH_FAILED = 'BlogCardActions/BLOG_CARD_FETCH_FAILED';

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG_CARD_FETCH_REQUESTED:
      return {
        ...state,
        blogCardsLoadInProgress: true,
        blogCardsHaveLoaded: false
      };
    case BLOG_CARD_FETCH_SUCCESS:
      return {
        ...state,
        blogCardsLoadInProgress: false,
        blogCardsHaveLoaded: true
      };
    case BLOG_CARD_FETCH_FAILED:
      return {
        ...state,
        blogCardsLoadInProgress: false
      };
    default:
      return state;
  }
};
