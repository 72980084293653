import { Model, attr, fk } from 'redux-orm';

class CartItem extends Model {
  static reducer(action, SessionModel) {
    switch (action.type) {
      case 'CREATE_CART_ITEM':
      case 'UPDATE_CART_ITEM':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_CART_ITEM':
        SessionModel.withId(action.payload.id).delete();
        break;
      case 'DELETE_ALL_CART_ITEMS':
        SessionModel.all().delete();
        break;
      default:
        break;
    }
  }
}

CartItem.modelName = 'CartItem';

CartItem.fields = {
  id: attr(),
  variation: fk('ProductVariationModel'),
  product: fk('ProductModel'),
  quantity: attr(),
};

CartItem.options = {
  idAttribute: 'id'
};

export default CartItem;
