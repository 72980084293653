export const FONTS_LOAD_REQUESTED = 'FontsActions/FONTS_LOAD_REQUESTED';
export const FONTS_LOAD_SUCCESS = 'FontsActions/FONTS_LOAD_SUCCESS';
export const FONTS_LOAD_FAILURE = 'FontsActions';

const initialState = {
  fontsHaveLoaded: false,
  fontsLoadInProgress: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FONTS_LOAD_REQUESTED:
      return {
        ...state,
        fontsLoadInProgress: true
      };
    case FONTS_LOAD_SUCCESS:
      return {
        ...state,
        fontsHaveLoaded: true,
        fontsLoadInProgress: false
      };
    case FONTS_LOAD_FAILURE:
      return {
        ...state,
        fontsHaveLoaded: false,
        fontsLoadInProgress: false
      };
    default:
      return state;
  }
};

export const setFontsStatus = status => {
  return dispatch => {
    switch (status) {
      case 'loading':
        dispatch({
          type: FONTS_LOAD_REQUESTED
        });
        break;
      case 'active':
        dispatch({
          type: FONTS_LOAD_SUCCESS
        });
        break;
      case 'failure':
        dispatch({
          type: FONTS_LOAD_FAILURE
        });
        break;
      default:
        break;
    }
  };
};

export const setFontsLoaded = () => {
  return dispatch => {
    dispatch({
      type: FONTS_LOAD_SUCCESS
    });
  };
};

export const setFontsLoading = () => {
  return dispatch => {
    dispatch({
      type: FONTS_LOAD_REQUESTED
    });
  };
};
