const initialState = {
  serverDatetime: [],
  dateTimeHasLoaded: false,
  dateTimeLoadInProgress: false,
  selectedShippingDates: {},
  shippingPolicyConsent: false,
  preferredDeliveryTime: 0
};

export const SHIPPING_FETCH_DATETIME_REQUESTED = 'ShippingActions/SHIPPING_METHOD_FETCH_REQUESTED';
export const SHIPPING_FETCH_DATETIME_SUCCESS = 'ShippingActions/SHIPPING_METHOD_FETCH_SUCCESS';
export const SHIPPING_FETCH_DATETIME_FAILED = 'ShippingActions/SHIPPING_METHOD_FETCH_FAILED';
export const SHIPPING_SET_SELECTED_DATETIME = 'ShippingActions/SHIPPING_SET_SELECTED_DATETIME';
export const SHIPPING_RESET_SHIPPING_DATES = 'ShippingActions/SHIPPING_RESET_SHIPPING_DATES';
export const SHIPPING_SET_PREFERRED_DELIVERY = 'ShippingActions/SHIPPING_SET_PREFERRED_DELIVERY';
export const SHIPPING_POLICY_SET_CONSENT = 'ShippingActions/SHIPPING_POLICY_SET_CONSENT'; // TODO: This has ended up 'dateTime' in redux. This is definitely the wrong place for it.
export const SHIPPING_RESET_SHIPPING_INFO = 'ShippingActions/SHIPPING_RESET_SHIPPING_INFO';

export default (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_FETCH_DATETIME_REQUESTED:
      return {
        ...state,
        dateTimeLoadInProgress: true,
        dateTimeHasLoaded: false
      };
    case SHIPPING_FETCH_DATETIME_SUCCESS:
      return {
        ...state,
        dateTimeHasLoaded: true,
        dateTimeLoadInProgress: false,
        serverDatetime: action.payload
      };
    case SHIPPING_FETCH_DATETIME_FAILED:
      return {
        ...state,
        dateTimeLoadInProgress: false
      };
    case SHIPPING_SET_SELECTED_DATETIME:
      return {
        ...state,
        selectedShippingDates: {
          ...state.selectedShippingDates,
          [action.payload.shippingServiceCode]: action.payload.date
        }
      };
    case SHIPPING_RESET_SHIPPING_DATES:
      return {
        ...state,
        selectedShippingDates: []
      };
    case SHIPPING_POLICY_SET_CONSENT:
      return {
        ...state,
        shippingPolicyConsent: action.payload
      };
    case SHIPPING_SET_PREFERRED_DELIVERY:
      return {
        ...state,
        preferredDeliveryTime: action.payload
      };
    case SHIPPING_RESET_SHIPPING_INFO:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
