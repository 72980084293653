export const CHECKOUT_FORM_OPTIONS_CHANGE_FIELD =
  'CheckoutFormOptions/CHECKOUT_FORM_OPTIONS_CHANGE_FIELD';
export const CHECKOUT_FORM_OPTIONS_RESET_FORM =
  'CheckoutFormOptions/CHECKOUT_FORM_OPTIONS_RESET_FORM';
export const initialState = {
  cardMessage: '',
  cardMessageOptOut: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_FORM_OPTIONS_CHANGE_FIELD:
      return {
        ...state,
        [action.fieldName]: action.fieldValue
      };
    case CHECKOUT_FORM_OPTIONS_RESET_FORM:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
