const initialState = {
  drupalImageLoadInProgress: [],
  drupalImageHasLoaded: [],
  drupalImageDerivatives: []
};

export const DRUPAL_IMAGE_DERIVATIVES_FETCH_REQUESTED = 'DrupalImageActions/DRUPAL_IMAGE_DERIVATIVES_FETCH_REQUESTED';
export const DRUPAL_IMAGE_DERIVATIVES_FETCH_SUCCESS = 'DrupalImageActions/DRUPAL_IMAGE_DERIVATIVES_FETCH_SUCCESS';
export const DRUPAL_IMAGE_DERIVATIVES_FETCH_FAILED = 'DrupalImageActions/DRUPAL_IMAGE_DERIVATIVES_FETCH_FAILED';

export default (state = initialState, action) => {
  switch (action.type) {
    case DRUPAL_IMAGE_DERIVATIVES_FETCH_REQUESTED:
      return {
        ...state,
        drupalImageLoadInProgress: {
          ...state.drupalImageLoadInProgress,
          [action.mediaPath]: true
        }
      };
    case DRUPAL_IMAGE_DERIVATIVES_FETCH_SUCCESS:
      return {
        ...state,
        drupalImageLoadInProgress: {
          ...state.drupalImageLoadInProgress,
          [action.mediaPath]: false
        },
        drupalImageHasLoaded: {
          ...state.drupalImageHasLoaded,
          [action.mediaPath]: true
        },
        drupalImageDerivatives: {
          ...state.drupalImageDerivatives,
          [action.mediaPath]: action.payload
        }
      };
    case DRUPAL_IMAGE_DERIVATIVES_FETCH_FAILED:
      return {
        drupalImageLoadInProgress: {
          ...state.drupalImageLoadInProgress,
          [action.mediaPath]: false
        },
        drupalImageHasLoaded: {
          ...state.drupalImageHasLoaded,
          [action.mediaPath]: false
        }
      };
    default:
      return state;
  }
}