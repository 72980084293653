export const CHECKOUT_FORM_RECIPIENT_CHANGE_FIELD =
  'CheckoutFormRecipientActions/CHECKOUT_FORM_RECIPIENT_CHANGE_FIELD';
export const CHECKOUT_FORM_RECIPIENT_RESET_FORM =
  'CheckoutFormRecipientActions/CHECKOUT_FORM_RECIPIENT_RESET_FORM';

export const initialState = {
  firstName: '',
  lastName: '',
  streetAddress: '',
  suite: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_FORM_RECIPIENT_CHANGE_FIELD:
      return {
        ...state,
        [action.fieldName]: action.fieldValue
      };
    case CHECKOUT_FORM_RECIPIENT_RESET_FORM:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
