import { attr, Model, fk, many } from 'redux-orm';

class ProductVariationModel extends Model {


  static reducer(action, SessionModel) {

    let variation;
    switch (action.type) {
      case 'CREATE_PRODUCT_VARIATION':
      case 'UPDATE_PRODUCT_VARIATION':

        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_PRODUCT_VARIATION':

        SessionModel.withId(action.payload.id).delete();
        break;
      case 'ADD_SLIDESHOW_IMAGE_TO_VARIATION':
        variation = SessionModel.withId(action.variationId);

        // Doesn't automatically check for duplicates
        if(!variation.slideShowImages.filter((set) => set.id === action.imageId).exists()){
          variation.slideShowImages.add(action.imageId);
        }
        break;

      case 'REMOVE_SLIDESHOW_IMAGE_FROM_VARIATION':
        variation = SessionModel.withId(action.variationId);

        // Doesn't automatically check for duplicates
        if(!variation.slideShowImages.filter((set) => set.id === action.imageId).exists()){
          variation.slideShowImages.remove(action.imageId);
        }
        break;

      case 'PURGE_PRODUCT_VARIATIONS':

        SessionModel.all().delete();
        break;
      default:
        break;
    }
  }

  static parse(rawData) {

    return {
      id: rawData.entityUuid,
      product: rawData.productId.entity.entityUuid,
      vid: rawData.variationId,
      title: rawData.title,
      path: rawData.entityUrl.path,
      size: rawData.attributeSize ? rawData.attributeSize.entity.name : null,
      price: rawData.price.number,
      stems: rawData.fieldStems
    };
  }
}

ProductVariationModel.modelName = 'ProductVariationModel';

ProductVariationModel.fields = {
  id: attr(),
  product: fk({to: 'ProductModel', as: 'product', relatedName: 'variations'}),
  vid: attr(),
  title: attr(),
  thumbnail: fk({to: 'Image', as: 'thumbnail', relatedName: 'productThumbnails'}),
  slideShowImages: many({to: 'Image', as: 'slideShowImages', relatedName: 'productSlideShows'}),
  fieldStems: attr(),
  alt: attr(),
  path: attr()
};

ProductVariationModel.options = {
  idAttribute: 'id'
};

export default ProductVariationModel;
