import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import orm from '_system/orm';

import './CartIcon.scss';

class CartIcon extends React.Component {
  render() {
    return (
      <div className="shopping-cart-link">
        <a href="/cart"><Icon name="shop" size='big'>{this.props.cartItemCount}</Icon></a>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const session = orm.session(state.orm);
  const { CartItem } = session;

  return {
    cartItemCount: CartItem.count()
  }
}

export default connect(mapStateToProps, null)(CartIcon);