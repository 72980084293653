import { Model, attr } from 'redux-orm';

class FeatureCard extends Model {
  static reducer(action, SessionModel) {
    switch (action.type) {
      case 'CREATE_FEATURE_CARD':
      case 'UPDATE_FEATURE_CARD':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_FEATURE_CARD':
        SessionModel.withId(action.payload.id).delete();
        break;
      default:
        break;
    }
  }

  static parse(rawData) {
    const parsedData = {
      id: rawData.uuid,
      title: rawData.title,
      body: rawData.body ? rawData.body.processed : null,
      cardLinkLabel: rawData.fieldCardLink.title,
      cardLinkUrl: rawData.fieldCardLink.url.path,
      smallFormatBgImage: rawData.fieldBackgroundImage.smallFormatImage.url,
      smallFormatBgImageWidth: rawData.fieldBackgroundImage.smallFormatImage.width,
      smallFormatBgImageHeight: rawData.fieldBackgroundImage.smallFormatImage.height,
      largeFormatBgImage: rawData.fieldBackgroundImage.largeFormatImage.url,
      largeFormatBgImageWidth: rawData.fieldBackgroundImage.largeFormatImage.width,
      largeFormatBgImageHeight: rawData.fieldBackgroundImage.largeFormatImage.height,
      alignment: rawData.fieldAlignment,
      alt: rawData.fieldBackgroundImage.alt
    };

    return parsedData;
  }
}

FeatureCard.modelName = 'FeatureCard';

FeatureCard.fields = {
  id: attr(),
  title: attr(),
  body: attr(),
  cardLinkLabel: attr(),
  cardLinkUrl: attr(),
  smallFormatBgImage: attr(),
  smallFormatBgImageWidth: attr(),
  smallFormatBgImageHeight: attr(),
  largeFormatBgImage: attr(),
  largeFormatBgImageWidth: attr(),
  largeFormatBgImageHeight: attr(),
  alignment: attr()
};

FeatureCard.options = {
  idAttribute: 'id'
};

export default FeatureCard;
