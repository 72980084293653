import React from 'react';
import './IntersectionObserver.scss';

require('intersection-observer');

export default class extends React.Component {
  targetContainerRef;

  options;

  observer;

  constructor(props) {
    super(props);

    this.state = {
      hasIntersected: false
    };

    this.targetContainerRef = React.createRef();

    this.options = {
      root: this.props.root || null,
      rootMargin: this.props.margin || "0px",
      threshold: this.props.threshold || 0
    };
  }

  componentDidMount() {

    this.observer = new IntersectionObserver(this.load, this.options);
    this.observer.observe(this.targetContainerRef.current);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.targetContainerRef.current);
  }

  load = (entries) => {
    if(!this.props.continueObserving && !this.state.hasIntersected) {
      const currentEntry = entries.find(entry => entry.target === this.targetContainerRef.current);

      if(currentEntry && currentEntry.isIntersecting) {
        this.setState({
          ...this.state,
          hasIntersected: true
        });
        if(this.props.onIntersection) {
          this.props.onIntersection(entries);
        }

        this.observer.unobserve(this.targetContainerRef.current);
      }
    } else if (this.props.continueObserving && this.props.onIntersection) {
      this.props.onIntersection(entries);
    }
  };

  render() {
    const { children = null, continueObserving } = this.props;

    return (
      <div
        className="intersection-observer"
        ref={this.targetContainerRef}
        style={{ ...this.props.style }}
      >
        { continueObserving ? children : this.state.hasIntersected && children }
      </div>
    )
  }
}