const initialState = {
  paragraphLoadInProgress: false,
  paragraphHaveLoaded: false,
  nodeContext: null
};

export const PARAGRAPH_FETCH_REQUESTED = 'DynamicActions/PARAGRAPH_FETCH_REQUESTED';
export const PARAGRAPH_FETCH_SUCCESS = 'DynamicActions/PARAGRAPH_FETCH_SUCCESS';
export const PARAGRAPH_FETCH_FAILED = 'DynamicActions/PARAGRAPH_FETCH_FAILED';
export const SAVE_NODE_CONTEXT = 'DynamicActions/SAVE_NODE_CONTEXT';

export default (state = initialState, action) => {
  switch (action.type) {
    case PARAGRAPH_FETCH_REQUESTED:
      return {
        ...state,
        paragraphLoadInProgress: true,
        paragraphHaveLoaded: false
      };
    case PARAGRAPH_FETCH_SUCCESS:
      return {
        ...state,
        paragraphLoadInProgress: false,
        paragraphHaveLoaded: true
      };
    case PARAGRAPH_FETCH_FAILED:
      return {
        ...state,
        paragraphLoadInProgress: false
      };
    case SAVE_NODE_CONTEXT:
      return {
        ...state,
        nodeContext: action.payload
      };
    default:
      return state;
  }
};
