import { Model, attr } from 'redux-orm';

class Image extends Model {
  static reducer(action, SessionModel) {
    switch(action.type) {
      case 'CREATE_IMAGE':
      case 'UPDATE_IMAGE':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_IMAGE':
        SessionModel.widthId(action.payload.id).delete();
        break;
      case 'PURGE_IMAGES':
        SessionModel.all().delete()
        break;
      default:
        break;
    }
  }
}

Image.modelName = 'Image';

Image.fields = {
  id: attr(),
  alt: attr(),
  title: attr()
};

Image.options = {
  idAttribute: 'id'
};

export default Image;