const initialState = {
  shippingMethodsLoadInProgress: false,
  shippingMethodsHaveLoaded: false,
  shippingMethods: [],
  selectedShippingMethod: null,
  recommendedShippingMethod: null
};

export const SHIPPING_METHOD_FETCH_REQUESTED = 'ShippingMethodActions/SHIPPING_METHOD_FETCH_REQUESTED';
export const SHIPPING_METHOD_FETCH_SUCCESS = 'ShippingMethodActions/SHIPPING_METHOD_FETCH_SUCCESS';
export const SHIPPING_METHOD_FETCH_FAILED = 'ShippingMethodActions/SHIPPING_METHOD_FETCH_FAILED';
export const SHIPPING_METHOD_SELECTED = 'ShippingMethodActions/SHIPPING_METHOD_SELECTED';
export const SHIPPING_METHOD_RECOMMENDED = 'ShippingMethodActions/SHIPPING_METHOD_RECOMMENDED';
export const SHIPPING_METHOD_RESET = 'ShippingMethodActions/SHIPPING_METHOD_RESET';

export default (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING_METHOD_FETCH_REQUESTED:
      return {
        ...state,
        shippingMethodsLoadInProgress: true,
        shippingMethodsHaveLoaded: false
      };
    case SHIPPING_METHOD_FETCH_SUCCESS:
      return {
        ...state,
        shippingMethodsLoadInProgress: false,
        shippingMethodsHaveLoaded: true,
        shippingMethods: action.payload
      };
    case SHIPPING_METHOD_FETCH_FAILED:
      return {
        ...state,
        shippingMethodsLoadInProgress: false
      };
    case SHIPPING_METHOD_SELECTED:
      return {
        ...state,
        selectedShippingMethod: action.payload
      };
    case SHIPPING_METHOD_RECOMMENDED:
      return {
        ...state,
        recommendedShippingMethod: action.payload
      };
    case SHIPPING_METHOD_RESET:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
