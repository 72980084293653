import { attr, Model, many } from 'redux-orm';

class ProductModel extends Model {
  static reducer(action, SessionModel) {

    let product;
    switch(action.type) {
      case 'CREATE_PRODUCT':
      case 'UPDATE_PRODUCT':
        SessionModel.upsert(action.payload);
        break;
      case 'DELETE_PRODUCT':
        SessionModel.withId(action.payload.id).delete();
        break;
      case 'PURGE_PRODUCTS':

        SessionModel.all().delete();

        break;

      case 'PRODUCT_ADD_TAG':
        product = SessionModel.withId(action.product.id);
        if(!product.tags.filter((set) => set.id === action.payload.id).exists()) {
          product.tags.add(action.payload.id);
        }
        break;
      default:
        break;
    }
  }

  static parse(rawData) {
    return {
      id: rawData.entityUuid,
      productType: rawData.entityBundle,
      title: rawData.title,
      body: rawData.body.processed,
      stores: rawData.stores.map(store => store.targetId).join('|'),
      weight: rawData.fieldWeight
    };
  }
}

ProductModel.modelName = 'ProductModel';

ProductModel.fields = {
  id: attr(),
  productType: attr(),
  title: attr(),
  body: attr(),
  stores: attr(),
  tags: many('Tag', 'products'),
  weight: attr()
};

ProductModel.options = {
  idAttribute: 'id'
};

export default ProductModel;
