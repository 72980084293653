import React from 'react';
import { Sidebar, Menu, Icon, Grid } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

import './SidebarNav.scss';
import logo from 'logo.png';

class SidebarNav extends React.PureComponent {

  render() {
    return (
      <Sidebar
        as={Menu}
        visible={this.props.visible}
        vertical
        inverted
        direction="right"
        animation="overlay"
        width="wide"
        onHide={() => this.props.hideHandler()}
      >
        <div className="sidebar-header">
          <div className="sidebar-close-wrapper">
            <Icon
              inverted
              name="close"
              size="large"
              onClick={() => this.props.hideHandler()}
            />
          </div>
          <div className="sidebar-logo">
            <Grid>
              <Grid.Row centered>
                <Grid.Column width={5}><img src={logo} alt="Bespoke Blossoms Logo" /></Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <Menu.Item as={NavLink} name="home" to="/" exact>Home</Menu.Item>
        <Menu.Item as={NavLink} name="shop" to="/shop" exact>Shop</Menu.Item>
        <Menu.Item as={NavLink} name="weddings" to="/weddings" exact>Weddings</Menu.Item>
        <Menu.Item as={NavLink} name="about" to="/about" exact>About Us</Menu.Item>
        <div className="sidebar-phone-section">
          <h3>Have a question?</h3>
          <a className="ui primary button" href="tel:250-382-0243">
            <Icon name="phone" />
            250-382-0243
          </a>
        </div>
      </Sidebar>
    );
  }
}

export default SidebarNav;
