const initialState = {

  productIndexLoadInProgress: false,
  productIndexHasLoaded: false,
  timeLastFetched: false
};

export const PRODUCT_INDEX_FETCH_REQUESTED = 'ProductIndexActions/PRODUCT_INDEX_FETCH_REQUESTED';
export const PRODUCT_INDEX_FETCH_SUCCESS = 'ProductIndexActions/PRODUCT_INDEX_FETCH_SUCCESS';
export const PRODUCT_INDEX_FETCH_FAILED = 'ProductIndexActions/PRODUCT_INDEX_FETCH_FAILED';

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_INDEX_FETCH_REQUESTED:
      return {
        ...state,
        productIndexLoadInProgress: true,
        productIndexHasLoaded: false
      };
    case PRODUCT_INDEX_FETCH_SUCCESS:
      return {
        ...state,
        productIndexLoadInProgress: false,
        productIndexHasLoaded: true,
        timeLastFetched: new Date()
      };
    case PRODUCT_INDEX_FETCH_FAILED:
      return {
        ...state,
        productIndexLoadInProgress: false
      };
    default:
      return state;
  }
};
