import { ORM } from 'redux-orm';

import FeatureCard from '_pages/Home/_content/FeatureNews/models/FeatureCardModel';
import BlogModel from '_pages/Home/_content/Blog/models/BlogCardModel';
import TestimonyModel from '_pages/Home/_content/Blog/models/TestimonyCardModel';
import ProductModel from '_pages/Shop/Products/_models/ProductModel';
import ProductVariationModel from '_pages/Shop/Products/_models/ProductVariationModel';
import Tag from '_pages/Shop/Products/_models/Tag';
import CartItemModel from '_pages/Shop/Cart/models/CartItemModel';
import Image from '_regions/Images/_models/Image';
import ImageDerivative from '_regions/Images/_models/ImageDerivative';

const orm = new ORM({
  stateSelector: state => state.orm
});

orm.register(FeatureCard);
orm.register(BlogModel);
orm.register(TestimonyModel);
orm.register(ProductModel);
orm.register(ProductVariationModel);
orm.register(CartItemModel);
orm.register(Tag);
orm.register(Image);
orm.register(ImageDerivative);

export default orm;
