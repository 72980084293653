const initialState = {
  weddingGalleryLoadInProgress: false,
  weddingGalleryHasLoaded: false,
  galleryImages: null
};

export const WEDDING_GALLERY_FETCH_REQUESTED = 'WeddingGalleryActions/WEDDING_GALLERY_FETCH_REQUESTED';
export const WEDDING_GALLERY_FETCH_SUCCESS = 'WeddingGalleryActions/WEDDING_GALLERY_FETCH_SUCCESS';
export const WEDDING_GALLERY_FETCH_FAILED = 'WeddingGalleryActions/WEDDING_GALLERY_FETCH_FAILED';

export default(state = initialState, action) => {
  switch(action.type) {

    case WEDDING_GALLERY_FETCH_REQUESTED:
      return {
        ...state,
        weddingGalleryHasLoaded: false,
        weddingGalleryLoadInProgress: true
      };

    case WEDDING_GALLERY_FETCH_SUCCESS:
      return {
        ...state,
        weddingGalleryLoadInProgress: false,
        weddingGalleryHasLoaded: true,
        galleryImages: action.payload
      };

    case WEDDING_GALLERY_FETCH_FAILED:
      return {
        ...state,
        weddingGalleryLoadInProgress: false
      };
    default:
      return state;
  }
}