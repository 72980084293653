import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import history from '_system/history';
import { ConnectedRouter } from 'connected-react-router';
import WebfontLoader from '@dr-kobros/react-webfont-loader';

import './scss/global.scss';
import App from './App';
import { store, persistor } from './store';
import { FONTS_LOAD_REQUESTED, FONTS_LOAD_SUCCESS } from './_system/fontActions';

const fontLoadConfig = {
  custom: {
    families: [
      'SoakUpTheSun',
      'MontserratBold',
      'MontserratSemiBold',
      'Montserrat',
      'Merriweather'
    ],
    urls: ['/scss/fonts.scss']
  }
};

const fontLoadCallback = status => {
  switch (status) {
    case 'loading':
      store.dispatch({
        type: FONTS_LOAD_REQUESTED
      });
      break;
    case 'active':
      store.dispatch({
        type: FONTS_LOAD_SUCCESS
      });
      break;
    default:
      console.log(status);
      break;
  }
};

// persistor.purge(); // REDUX SHUNT

const target = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <ConnectedRouter history={history}>
        <WebfontLoader config={fontLoadConfig} onStatus={fontLoadCallback}>
          <App />
        </WebfontLoader>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);
