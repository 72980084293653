
export const filterUniques = (data, key, name) => {
  const result = [];
  data.forEach(item => {
    const existingItem = result.find(i => i.value === item[key]);
    if (!existingItem) {
      result.push({
        label: item[name],
        value: item[key]
      });
    }
  });
  return result;
};

export const resolveAssetUrl = path => {
  let url = `${process.env.REACT_APP_BESPOKE_ASSET_URL}${path}`;
  if (typeof path === 'string' && path.indexOf('http') !== -1) {
    const urlWithCdnHost = path.replace(
      process.env.REACT_APP_BESPOKE_SOURCE_URL,
      process.env.REACT_APP_BESPOKE_ASSET_URL
    );

    url = urlWithCdnHost;
  }

  return url;
};

export function convertRelToAbsUrl(url) {

  if (/^(https?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i.test(url)) {
    return url; // url is already absolute
  }

  const baseUrl = process.env.REACT_APP_BESPOKE_ASSET_URL.slice(0, -1); // removes last slash.

  if (url.charAt(0) === '/') {
    return baseUrl + url;
  }

  // Unsure...
  if (/^\s*$/.test(url)) {
    return '';
  }

  const cleanedUrl = url.substring(0, 2) === './' ? `.${url}` : url;


  const rebasedUrl = `${baseUrl}../${cleanedUrl}`;

  let resolvedUrl = '';
  while (/\/\.\.\//.test(rebasedUrl)) {
    const intermediateUrl = resolvedUrl !== '' ? resolvedUrl : rebasedUrl;
    resolvedUrl = intermediateUrl.replace(/[^/]+\/+\.\.\//g, '');
  }

  return resolvedUrl.replace(/\.$/, '').replace(/\/\./g, '').replace(/"/g, '%22')
    .replace(/'/g, '%27').replace(/</g, '%3C').replace(/>/g, '%3E');
}

/**
 * convertAllRelativeToAbsoluteUrls
 *
 * https://stackoverflow.com/a/7544757/1983903
 *
 * @param  {String} html
 * @return {String} updated html
 */
export function convertAllRelativeToAbsoluteUrls(html) {
  const att = '[^-a-z0-9:._]';
  const entityEnd = '(?:;|(?!\\d))';
  const ents = {
    ' ' : `(?:\\s|&nbsp;?|&#0*32${entityEnd}|&#x0*20${entityEnd})`,
    '(' : `(?:\\(|&#0*40${entityEnd}|&#x0*28${entityEnd})`,
    ')' : `(?:\\)|&#0*41${entityEnd}|&#x0*29${entityEnd})`,
    '.' : `(?:\\.|&#0*46${entityEnd}|&#x0*2e${entityEnd})`
  };
  const charMap = {};
  const s = `${ents[' ']}*`; // short-hand for common use
  const any = '(?:[^>"\']*(?:"[^"]*"|\'[^\']*\'))*?[^>]*';
  const slashRE = new RegExp(ae('/'), 'g');
  const dotRE = new RegExp(ae('.'), 'g');

  function ae(string) {
    const allCharsLowerCase = string.toLowerCase();
    const allCharsUpperCase = string.toUpperCase();
    let reRes = '';
    let reSub = null;

    if (ents[string]) {
      return ents[string];
    }

    for (let i = 0; i < string.length; i++) {
      const charLowerCase = allCharsLowerCase.charAt(i);
      if (charMap[charLowerCase]) {
        reRes += charMap[charLowerCase];
        continue;
      }
      const charUpperCase = allCharsUpperCase.charAt(i);
      reSub = [charLowerCase];
      reSub.push(`&#0*${charLowerCase.charCodeAt(0)}${entityEnd}`);
      reSub.push(`&#x0*${charLowerCase.charCodeAt(0).toString(16)}${entityEnd}`);

      if (charLowerCase !== charUpperCase) {
        reSub.push(`&#0*${charUpperCase.charCodeAt(0)}${entityEnd}`);
        reSub.push(`&#x0*${charUpperCase.charCodeAt(0).toString(16)}${entityEnd}`);
      }
      reSub = `(?:${reSub.join('|')})`;
      reRes += (charMap[charLowerCase] = reSub);
    }
    return (ents[string] = reRes);
  }

  function by(match, group1, group2, group3) {
    return group1 + convertRelToAbsUrl(group2) + group3;
  }

  function by2(match, group1, group2, group3) {
    const newGroup2 = group2.replace(slashRE, '/').replace(dotRE, '.');
    return group1 + convertRelToAbsUrl(newGroup2) + group3;
  }

  function cr(selector, attribute, marker, delimiter, end) {
    var re1 = null,
      re2 = null,
      re3 = null;

    if (typeof selector === 'string') {
      selector = new RegExp(selector, 'gi');
    }

    attribute = att + attribute;
    marker = typeof marker === 'string' ? marker : '\\s*=\\s*';
    delimiter = typeof delimiter === 'string' ? delimiter : '';
    end = typeof end === 'string' ? '?)(' + end : ')(';

    re1 = new RegExp('(' + attribute + marker + '")([^"' + delimiter + ']+' + end + ')', 'gi');
    re2 = new RegExp('(' + attribute + marker + '\')([^\'' + delimiter + ']+' + end + ')', 'gi');
    re3 = new RegExp('(' + attribute + marker + ')([^"\'][^\\s>' + delimiter + ']*' + end + ')', 'gi');

    html = html.replace(selector, function (match) {
      return match.replace(re1, by).replace(re2, by).replace(re3, by);
    });
  }

  function cri(selector, attribute, front, flags, delimiter, end) {
    var re1 = null,
      re2 = null,
      at1 = null,
      at2 = null,
      at3 = null,
      handleAttr = null;

    if (typeof selector === 'string') {
      selector = new RegExp(selector, 'gi');
    }

    attribute = att + attribute;
    flags = typeof flags === 'string' ? flags : 'gi';
    re1 = new RegExp('(' + attribute + '\\s*=\\s*")([^"]*)', 'gi');
    re2 = new RegExp("(" + attribute + "\\s*=\\s*')([^']+)", 'gi');
    at1 = new RegExp('(' + front + ')([^"]+)(")', flags);
    at2 = new RegExp("(" + front + ")([^']+)(')", flags);

    if (typeof delimiter === 'string') {
      end = typeof end === 'string' ? end : '';
      at3 = new RegExp('(' + front + ')([^"\'][^' + delimiter + ']*' + (end ? '?)(' + end + ')' : ')()'), flags);
      handleAttr = function (match, g1, g2) {
        return g1 + g2.replace(at1, by2).replace(at2, by2).replace(at3, by2);
      };
    } else {
      handleAttr = function (match, g1, g2) {
        return g1 + g2.replace(at1, by2).replace(at2, by2);
      };
    }
    html = html.replace(selector, function (match) {
      return match.replace(re1, handleAttr).replace(re2, handleAttr);
    });
  }

  cri(`<meta${any}${att}http-equiv\\s*=\\s*(?:"${ae('refresh')}"${any}>|'${ae('refresh')}'${any}>|${ae('refresh')}(?:${ae(' ')}${any}>|>))`, 'content', ae('url') + s + ae('=') + s, 'i');

  cr(`<${any}${att}href\\s*=${any}>`, 'href'); /* Linked elements */
  cr(`<${any}${att}src\\s*=${any}>`, 'src'); /* Embedded elements */

  cr(`<object${any}${att}data\\s*=${any}>`, 'data'); /* <object data= > */
  cr(`<applet${any}${att}codebase\\s*=${any}>`, 'codebase'); /* <applet codebase= > */

  // <param name=movie value= >
  cr(`<param${any}${att}name\\s*=\\s*(?:"${ae('movie')}"${any}>|'${ae('movie')}'${any}>|${ae('movie')}(?:${ae(' ')}${any}>|>))`, 'value');

  cr(/<style[^>]*>(?:[^"']*(?:"[^"]*"|'[^']*'))*?[^'"]*(?:<\/style|$)/gi,
    'url', '\\s*\\(\\s*', '', '\\s*\\)'); /* <style> */
  cri(`<${any}${att}style\\s*=${any}>`, 'style',
    ae('url') + s + ae('(') + s, 0, s + ae(')'), ae(')')); // < style=" url(...) " >

  return html;
}
// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const formatCurrency = number => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(number);
};

export const isEmailAddress = candidate => {
  const regExp = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(String(candidate).toLowerCase());
};



export default () => {};
