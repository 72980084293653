import React from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink, withRouter } from 'react-router-dom';

class MainNav extends React.PureComponent {
  render() {
    const menuItems = this.props.items.map(item => {
      const { name, label, route } = item;

      if (item.exact) {
        return (
          <Menu.Item key={`main-nav-item-${name}`} name={name} to={route} as={NavLink} exact>
            {label}
          </Menu.Item>
        );
      }

      return (
        <Menu.Item key={`main-nav-item-${name}`} name={name} to={route} as={NavLink}>
          {label}
        </Menu.Item>
      );
    });

    return (
      <Menu borderless className={this.props.className}>
        {menuItems}
      </Menu>
    );
  }
}

export default withRouter(MainNav);
