export const CHECKOUT_FORM_SENDER_CHANGE_FIELD =
  'CheckoutFormRecipientActions/CHECKOUT_FORM_SENDER_CHANGE_FIELD';
export const CHECKOUT_FORM_SENDER_RESET_FORM =
  'CheckoutFormRecipientActions/CHECKOUT_FORM_SENDER_RESET_FORM';

export const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  postalCode: '',
  contactPreference: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_FORM_SENDER_CHANGE_FIELD:
      return {
        ...state,
        [action.fieldName]: action.fieldValue
      };
    case CHECKOUT_FORM_SENDER_RESET_FORM:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
