import React from 'react';
import { canUseWebP } from 'react-img-webp';

import { makeCancelable } from '_system/components/Utils';

import DefaultPlaceholder from 'images/placeholder.png';

// TODO: Add adaptive image support
/*  TODO: Add smooth image transitions. Look into react transition
    https://reactcommunity.org/react-transition-group/, React Motion
    https://github.com/chenglou/react-motion, and React Spring
    https://github.com/react-spring/react-spring
*/

class BackgroundImageWrapper extends React.Component {
  cancelable = null;

  constructor(props) {
    super(props);

    this.state = {
      imageLoaded: false,
      isLoadError: false,
      errorMsg: '',
      placeholder: {
        attributes: {}
      }
    };
  }

  componentDidMount() {
    this.reloadImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.breakpoint !== prevProps.breakpoint) {
      this.reloadImage();
    }
  }

  componentWillUnmount() {
    if (this.cancelable) {
      this.cancelable.cancel();
    }
  }

  reloadImage() {
    if (this.props.placeholder) {
      this.setState({
        ...this.state,
        placeholder: {
          ...this.state.placeholder,
          attributes: {
            ...this.state.placeholder.attributes,
            minHeight: this._getPlaceholderHeight()
          }
        }
      });
    }

    if (this.props.src || this.props.srcSet) {
      const imgSrc = this._determineImgSrc();
      const promise = new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', err => reject(err));
        img.src = imgSrc;
      });
      this.cancelable = makeCancelable(promise);
      this.cancelable.promise
        .then(() => {
          this.setState({
            ...this.state,
            imageLoaded: true
          });
        })
    }
  }

  _determineImgSrc() {
    if (!this.state.imageLoaded && this.props.placeholder) {
      return this.props.placeholder;
    }

    if (this.props.srcSet[this.props.breakpoint] && this.state.imageLoaded) {
      if (canUseWebP() && this.props.srcSet[this.props.breakpoint].webp) {
        return this.props.srcSet[this.props.breakpoint].webp;
      }

      if (this.props.srcSet[this.props.breakpoint].src) {
        return this.props.srcSet[this.props.breakpoint].src;
      }
    }

    if (this.state.imageLoaded && this.props.src) {
      return this.props.src;
    }

    return DefaultPlaceholder;
  }

  _getPlaceholderHeight() {
    if (this.props.placeholder) {
      const img = new Image();
      img.src = this.props.placeholder;

      return (window.innerWidth * img.height) / img.width;
    }
    return null;
  }

  render() {
    const imgUrl = this._determineImgSrc();
    const placeholderAttrs = this.props.options.placeholder.style;
    const loadedAttrs = this.props.options.styles.loaded;

    let styles = {
      backgroundImage: `url(${imgUrl})`,
      backgroundSize: this.props.options.bannerCoverage === 'full' ? 'cover' : 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      minHeight: this.props.minHeightBanner || 500
    };

    if (this.props.placeholder && !this.state.imageLoaded) {
      styles = {
        ...styles,
        ...placeholderAttrs
      };
    } else {
      styles = {
        ...styles,
        ...loadedAttrs
      };
    }

    return React.createElement(
      'div',
      { style: styles, className: this.props.className },
      this.props.children
    );
  }
}

export default BackgroundImageWrapper;
