const initialState = {
  orderCreated: null,
  orderNumber: null,
  cardInfo: {
    nonce: null,
    cardType: null,
    last4: null,
    exp_month: null,
    exp_year: null
  },
  checkoutInProgress: false,
  checkoutCompleted: false,
  orderCreationInProgress: false,
  shipmentCreationInProgress: false,
  paymentCreationInProgress: false,
  paymentCompleted: false,
  isValidCardNumber: false,
  isValidCardExpirationDate: false,
  isValidCardCvvCode: false,
  orderCreationFailed: false,
  orderId: null
};

export const CHECKOUT_REVIEW_ORDER_CREATION_REQUESTED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_ORDER_CREATION_REQUESTED';
export const CHECKOUT_REVIEW_ORDER_CREATION_SUCCESS =
  'CheckoutReviewActions/CHECKOUT_REVIEW_ORDER_CREATION_SUCCESS';
export const CHECKOUT_REVIEW_ORDER_CREATION_FAILED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_ORDER_CREATION_FAILED';
export const CHECKOUT_REVIEW_SET_CARD_INFO = 'CheckoutReviewActions/CHECKOUT_REVIEW_SET_CARD_INFO';
export const CHECKOUT_REVIEW_SHIPMENT_CREATION_REQUESTED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_SHIPMENT_CREATION_REQUESTED';
export const CHECKOUT_REVIEW_SHIPMENT_CREATION_SUCCESS =
  'CheckoutReviewActions/CHECKOUT_REVIEW_SHIPMENT_CREATION_SUCCESS';
export const CHECKOUT_REVIEW_SHIPMENT_CREATION_FAILED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_SHIPMENT_CREATION_FAILED';
export const CHECKOUT_REVIEW_PAYMENT_CREATION_REQUESTED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_PAYMENT_CREATION_REQUESTED';
export const CHECKOUT_REVIEW_PAYMENT_CREATION_SUCCESS =
  'CheckoutReviewActions/CHECKOUT_REVIEW_PAYMENT_CREATION_SUCCESS';
export const CHECKOUT_REVIEW_PAYMENT_CREATION_FAILED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_PAYMENT_CREATION_FAILED';
export const CHECKOUT_REVIEW_CHECKOUT_REQUESTED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_CHECKOUT_REQUESTED';
export const CHECKOUT_REVIEW_CHECKOUT_SUCCESS =
  'CheckoutReviewActions/CHECKOUT_REVIEW_CHECKOUT_SUCCESS';
export const CHECKOUT_REVIEW_CHECKOUT_FAILED =
  'CheckoutReviewActions/CHECKOUT_REVIEW_CHECKOUT_FAILED';
export const CHECKOUT_RESET_CARD_INFO = 'CheckoutReviewActions/CHECKOUT_RESET_CARD_INFO';
export const CHECKOUT_COMPLETED = 'CheckoutReviewActions/CHECKOUT_COMPLETED';
export const CHECKOUT_VALID_CARD_NUMBER = 'CheckoutReviewActions/CHECKOUT_VALID_CARD_NUMBER';
export const CHECKOUT_VALID_CARD_EXPIRATION_DATE =
  'CheckoutReviewActions/CHECKOUT_VALID_CARD_EXPIRATION_DATE';
export const CHECKOUT_VALID_CARD_CVV_CODE = 'CheckoutReviewActions/CHECKOUT_VALID_CARD_CVV_CODE';

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_REVIEW_SET_CARD_INFO:
      return {
        ...state,
        cardInfo: action.payload
      };
    case CHECKOUT_REVIEW_CHECKOUT_REQUESTED:
      return {
        ...state,
        checkoutInProgress: true
      };
    case CHECKOUT_REVIEW_ORDER_CREATION_REQUESTED:
      return {
        ...state,
        orderCreationInProgress: true,
        orderCreationFailed: false
      };
    case CHECKOUT_REVIEW_ORDER_CREATION_SUCCESS: {
      const { orderCreated, orderNumber } = action.payload;

      return {
        ...state,
        orderCreationInProgress: false,
        orderCreationFailed: false,
        orderCreated,
        orderNumber
      };
    }
    case CHECKOUT_REVIEW_ORDER_CREATION_FAILED:
      return {
        ...state,
        orderCreationInProgress: false,
        orderCreationFailed: true
      };
    case CHECKOUT_REVIEW_SHIPMENT_CREATION_REQUESTED:
      return {
        ...state,
        shipmentCreationInProgress: true
      };
    // TODO: right now there's no confirmation of a shipment being created, so the response is the same. This is a Drupal problem.
    case CHECKOUT_REVIEW_SHIPMENT_CREATION_FAILED:
    case CHECKOUT_REVIEW_SHIPMENT_CREATION_SUCCESS:
      return {
        ...state,
        shipmentCreationInProgress: false
      };
    case CHECKOUT_REVIEW_CHECKOUT_SUCCESS:
    case CHECKOUT_REVIEW_CHECKOUT_FAILED:
      return {
        ...state,
        checkoutInProgress: false
      };
    case CHECKOUT_REVIEW_PAYMENT_CREATION_REQUESTED:
      return {
        ...state,
        paymentCreationInProgress: true,
        paymentCompleted: false
      };
    case CHECKOUT_REVIEW_PAYMENT_CREATION_SUCCESS:
      return {
        ...state,
        paymentCreationInProgress: false,
        paymentCompleted: true
      };
    case CHECKOUT_REVIEW_PAYMENT_CREATION_FAILED:
      return {
        ...state,
        paymentCreationInProgress: false,
        paymentCompleted: false
      };
    case CHECKOUT_RESET_CARD_INFO:
      return {
        ...state,
        ...initialState
      };
    case CHECKOUT_COMPLETED:
      return {
        ...state,
        checkoutCompleted: action.payload
      };
    case CHECKOUT_VALID_CARD_NUMBER:
      return {
        ...state,
        isValidCardNumber: action.payload
      };
    case CHECKOUT_VALID_CARD_EXPIRATION_DATE:
      return {
        ...state,
        isValidCardExpirationDate: action.payload
      };
    case CHECKOUT_VALID_CARD_CVV_CODE:
      return {
        ...state,
        isValidCardCvvCode: action.payload
      };
    default:
      return state;
  }
};
