import React from 'react';
import { Spring } from 'react-spring/renderprops';
import ErrorBoundary from '_system/ErrorBoundary';

export default class LoadedModule extends React.Component {

  onAnimationFrameCallback;

  constructor(props) {
    super(props);

    this.state = {
      Component: null,
      initializing: false
    };

    this.onAnimationFrameCallback = null;
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      Component: React.lazy(this.props.component),
      intializing: true
    });
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.onAnimationFrameCallback);
  }

  onInitialized = () => {
    this.onAnimationFrameCallback = window.requestAnimationFrame(() => {
      this.setState({
        initialization: false
      })
    })
  };

  render() {
    const { children, fallback, placeholder, style = {}, ...rest} = this.props;

    const { Component, initializing } = this.state;

    const defaultStyles = {
      width: "100%"
    };

    return (
      <div
        style={{
          ...style
        }}
      >
        <ErrorBoundary fallback={<div>An Error has occurred.</div>}>
          <React.Suspense fallback={placeholder}>
            <Spring
              from={{
                ...defaultStyles,
                opacity: !initializing
              }}
              to={{
                ...defaultStyles,
                opacity: initializing,
                display: "none"
              }}
            >
              {props => (
                <div style={props}>{React.cloneElement(placeholder)}</div>
              )}
            </Spring>
            <Spring
              from={{
                opacity: initializing,
                transform: `translate3d(0, -50px, 0)0`
              }}
              to={{
                opacity: !initializing,
                transform: `translate3d(0px, 0, 0)`
              }}
            >
              {props => Component && (
                <Component
                  {...rest /* eslint-disable-line react/jsx-props-no-spreading */}
                  style={{
                    position: "absolute",
                    height: "100%",
                    ...props
                  }}
                  onInitialized={this.onInitialized}
                />
              )}
            </Spring>
          </React.Suspense>
        </ErrorBoundary>
      </div>
    )
  }
}